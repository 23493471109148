import React, { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = (props) => {
  const { isDarkMode } = useContext(AppContext);
  const { user, subscribed, telegram } = props;
  let themeColor = `${!isDarkMode ? "black" : "white"}`;
  const data = {
    labels: [
      `Unsubscribed Users ${user - subscribed}`,
      `Only Subscribed Users ${subscribed - telegram}`,
      `Telegram & SubScribed Users ${telegram}`,
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [user - subscribed, subscribed - telegram, telegram],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        labels: {
          color: themeColor, // Set the text color here
        },
      },
    },
  };
  return (
    <div className="w-100 my-4 lg:my-0 dark:bg-gray-700 dark:text-white flex items-center justify-center">
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default PieChart;
