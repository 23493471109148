import React, { useContext, useEffect, useState } from "react";
import { HiCollection, HiUsers } from "react-icons/hi";
import PieChart from "../components/PieChart";
import UserTable from "../components/UserTable";
import { FaTelegramPlane, FaUserCheck } from "react-icons/fa";
import { AppContext } from "../context/AppContext";
import axios from "axios";
const AdminPage = () => {
  const { userData, setUserData, jwt } = useContext(AppContext);
  const [ercCount, setErcCount] = useState(0);
  const [alphaCount, setAlphaCount] = useState(0);
  useEffect(() => {
    async function fetchAllUsers() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/app/getUsers`,
          {
            headers: {
              Authorization: jwt,
            },
          }
        );
        setUserData(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    async function fetchErcCount() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/app/getCollectionsDetails`,
          {
            headers: {
              Authorization: jwt,
            },
          }
        );
        setErcCount(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    async function fetchAlphaCount() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ALPHA}/getAlphaCollectionDetails`,
          {
            headers: {
              Authorization: jwt,
            },
          }
        );
        setAlphaCount(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    if (jwt !== "") {
      fetchAllUsers();
      fetchErcCount();
      fetchAlphaCount();
    }
  }, [jwt]);

  return (
    <div className="bg-white  dark:bg-gray-800 flex-grow md:rounded-[30px]   md:shadow-lg md:my-4 h-100 ">
      <div className={`w-100 bg-white dark:bg-gray-800 px-3 md:rounded-[30px]`}>
        <h2 className="text-lg font-semibold text-gray-700 dark:text-gray-300 px-8 mt-6">
          Admin Page
        </h2>
        <div className="flex flex-col gap-2 mx-6 mt-6 w-100 md:flex-row md:justify-between md:items-center">
          <div className="w-100 lg:w-[10rem] gap-2 inline-flex justify-start items-center bg-[#E7E5FF] focus:ring-4 focus:outline-none focus:ring-gray-300 text-black dark:text-white rounded-lg px-2 py-2.5 dark:bg-gray-700 ">
            <HiCollection size={24} />
            <div className="text-left rtl:text-right">
              <div className="mb-1 text-xs font-bold">ERC404 Collections</div>
              <div className="-mt-1 font-sans text-sm font-semibold">
                {ercCount}
              </div>
            </div>
          </div>
          <div className="w-100 lg:w-[10rem] gap-2 inline-flex justify-start items-center bg-[#E7E5FF] focus:ring-4 focus:outline-none focus:ring-gray-300 text-black dark:text-white rounded-lg px-2 py-2.5 dark:bg-gray-700 ">
            <HiCollection size={24} />
            <div className="text-left rtl:text-right">
              <div className="mb-1 text-xs font-bold">Alpha Collections </div>
              <div className="-mt-1 font-sans text-sm font-semibold">
                {alphaCount}
              </div>
            </div>
          </div>
          <div className="w-100 lg:w-[10rem] gap-2 inline-flex justify-start items-center bg-[#E7E5FF] focus:ring-4 focus:outline-none focus:ring-gray-300 text-black dark:text-white rounded-lg px-2 py-2.5 dark:bg-gray-700 ">
            <HiUsers size={24} />
            <div className="text-left rtl:text-right">
              <div className="mb-1 text-xs font-bold">Users</div>
              <div className="-mt-1 font-sans text-sm font-semibold">
                {userData?.all?.length || "--"}
              </div>
            </div>
          </div>
          <div className="w-100 lg:w-[10rem] gap-2 inline-flex justify-start items-center bg-[#E7E5FF] focus:ring-4 focus:outline-none focus:ring-gray-300 text-black dark:text-white rounded-lg px-2 py-2.5 dark:bg-gray-700 ">
            <FaUserCheck size={24} />
            <div className="text-left rtl:text-right">
              <div className="mb-1 text-xs font-bold">Subscribed Users</div>
              <div className="-mt-1 font-sans text-sm font-semibold">
                {userData.subscribeCount || "--"}
              </div>
            </div>
          </div>
          <div className="w-100 lg:w-[10rem] gap-2 inline-flex justify-start items-center bg-[#E7E5FF] focus:ring-4 focus:outline-none focus:ring-gray-300 text-black dark:text-white rounded-lg px-2 py-2.5 dark:bg-gray-700 ">
            <FaTelegramPlane size={24} />
            {/* <HiUsers size={24} /> */}
            <div className="text-left rtl:text-right">
              <div className="mb-1 text-xs font-bold">Telegram Users</div>
              <div className="-mt-1 font-sans text-sm font-semibold">
                {userData.telegramCount || "--"}
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mx-6 mt-6 mb-6 items-start">
          <UserTable />
          <PieChart
            user={userData?.all?.length || 0}
            subscribed={userData?.subscribeCount || 0}
            telegram={userData?.telegramCount || 0}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
