import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaSort } from "react-icons/fa";
import { SiOpensea } from "react-icons/si";
import axios from "axios";
import { IoGlobeOutline } from "react-icons/io5";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import CopyToClipboard from "react-copy-to-clipboard";
import { MdContentCopy } from "react-icons/md";
import { AppContext } from "../context/AppContext";
import { IoIosArrowRoundBack } from "react-icons/io";
import Banner from "../components/Banner";
import { calculateTimeSince, convertToMillionFormat } from "../utils/utils";

const AlphaPage = () => {
  const [data, setData] = useState([]);
  const [sortField, setSortField] = useState("time");
  const [sortTime, setSortTime] = useState("decreasing");
  const [sortPrice, setSortPrice] = useState("decreasing");
  const [sortLiquidity, setSortLiquidity] = useState("decreasing");
  const [selectedSort, setSelectedSort] = useState("timedown");
  const [copied, setCopied] = useState("");
  const { isDarkMode } = useContext(AppContext);
  const navigate = useNavigate();
  const thresholdLiquidity = 10000;

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedSort(selectedValue);
    switch (selectedValue) {
      case "timeup":
        // Set state for case A
        setSortTime("increasing");
        setSortField("time");
        break;
      case "timedown":
        setSortTime("decreasing");
        setSortField("time");
        break;
      case "priceup":
        // Set state for case A
        setSortPrice("increasing");
        setSortField("price");
        break;
      case "pricedown":
        setSortPrice("decreasing");
        setSortField("price");
        break;
      case "liquiup":
        // Set state for case A
        setSortLiquidity("increasing");
        setSortField("liquidity");
        break;
      case "liquidown":
        setSortLiquidity("decreasing");
        setSortField("liquidity");
        break;
      default:
        // Set default state or handle other cases
        setSortTime("decreasing");
        setSortField("time");
    }
  };

  const handleCopy = (type) => {
    setCopied(type);
    // You can reset the copied state after a certain duration if needed
    setTimeout(() => setCopied(""), 2000);
    // Handle any other copy logic if needed
  };

  const handleSort = async (type) => {
    setSortField(type);
    if (type === "time") {
      if (sortTime === "increasing") {
        setSortTime("decreasing");
        fetchData("time");
      } else {
        setSortTime("increasing");
        fetchData("time");
      }
    } else if (type === "price") {
      if (sortPrice === "increasing") {
        setSortPrice("decreasing");
        fetchData("price");
      } else {
        setSortPrice("increasing");
        fetchData("price");
      }
    } else {
      // setSortField("price");
      if (sortLiquidity === "increasing") {
        setSortLiquidity("decreasing");
        fetchData("liquidity");
      } else {
        setSortLiquidity("increasing");
        fetchData("liquidity");
      }
    }
  };

  const fetchData = async (field) => {
    try {
      let order = null;
      if (field === "time") {
        order = sortTime;
      } else if (field === "price") {
        order = sortPrice;
      } else {
        order = sortLiquidity;
      }
      const { data } = await axios.get(
        `${process.env.REACT_APP_ALPHA}/getAlpha?sortBy=${field}&orderBy=${order}`
      );
      setData(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    // Call the async function
    fetchData(sortField);
    const fetchDataInterval = () => {
      fetchData(sortField);
    };
    const intervalId = setInterval(fetchDataInterval, 20000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [sortField, sortPrice, sortTime, sortLiquidity]);

  return (
    <div className="flex-grow dark:bg-gray-900 bg-[#f6f6f6] ">
      <Banner />
      <div className="mx-6 my-8 md:flex md:justify-between md:items-center lg:flex lg:justify-between lg:mx-32 lg:items-center ">
        <div className="flex items-center gap-2 mb-6 lg:mb-0 md:mb-0">
          <IoIosArrowRoundBack
            size={48}
            style={{
              backgroundColor: `${isDarkMode ? "#312F57" : "#ddd6fe"}`,
              color: `${isDarkMode ? "#ddd6fe" : "#6d28d9"}`,
              borderRadius: "50%",
              padding: "5px", // Adjust the padding value as needed
              cursor: "pointer",
            }}
            onClick={() => navigate(-1)}
          />
          <span className="flex flex-col items-start ">
            <h2 className="text-xl font-bold text-center dark:text-white">
              Alpha
            </h2>

            <nav class="flex" aria-label="Breadcrumb">
              <ol class="flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                <li class="inline-flex items-center">
                  <Link to={"/"}>
                    <a class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                      Home
                    </a>
                  </Link>
                </li>

                <li aria-current="page">
                  <div class="flex items-center">
                    <svg
                      class="rtl:rotate-180  w-3 h-3 mx-1 text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 9 4-4-4-4"
                      />
                    </svg>
                    <span class="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">
                      {/* {selectedCollection?.name} */} Alpha
                    </span>
                  </div>
                </li>
              </ol>
            </nav>
          </span>
        </div>

        <div className=" flex flex-col gap-2 w-fit mt-2 md:flex-row md:items-center lg:flex-row lg:items-center  lg:hidden ">
          <span
            htmlFor="countries"
            className="text-sm font-bold text-gray-900 dark:text-white w-fit"
          >
            Sort by
          </span>
          <select
            value={selectedSort}
            onChange={handleSelectChange}
            id="countries"
            className="bg-gray-50 border min-w-[20rem] border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option selected value="timedown">
              Time Descending
            </option>
            <option value="timeup">Time Ascending</option>
            <option value="pricedown">Price Dscending</option>
            <option value="priceup">Price Ascending</option>
            <option value="liquidown">Liquidity Descending</option>
            <option value="liquiup">Liquidity Ascending</option>
          </select>
        </div>
      </div>
      <div className="relative overflow-x-auto shadow-md rounded-2xl mx-6 lg:mx-32 p-2 bg-white dark:bg-gray-700 hidden lg:block">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-white ">
          <thead className="text-sm text-gray-700 bg-white dark:bg-gray-700 dark:text-white font-bold border-b dark:border-gray-900 ">
            <tr>
              <th className="cursor-pointer pl-6 py-3 dark:bg-gray-700  whitespace-nowrap ">
                Name
              </th>
              <th
                onClick={() => handleSort("price")}
                className="cursor-pointer pl-6 py-3 "
              >
                <div className="flex items-center justify-end whitespace-nowrap gap-1">
                  Price(USD) <FaSort />
                </div>
              </th>
              <th className="cursor-pointer pl-6 py-3 ">
                <div className="flex items-center justify-end whitespace-nowrap gap-1">
                  TXN (24H)
                </div>
              </th>
              <th className="cursor-pointer pl-6 py-3 ">
                <div className="flex items-center justify-end whitespace-nowrap gap-1">
                  Volume (24H)
                </div>
              </th>
              <th className="cursor-pointer pl-6 py-3 ">
                <div className="flex items-center justify-end whitespace-nowrap gap-1">
                  FDV
                </div>
              </th>
              <th
                onClick={() => handleSort("liquidity")}
                className="cursor-pointer pl-6 py-3 "
              >
                <div className="flex items-center justify-end whitespace-nowrap gap-1">
                  Liquidity <FaSort />
                </div>
              </th>
              <th className="cursor-pointer pl-6 py-3 ">
                <div className="flex items-center justify-end whitespace-nowrap gap-1">
                  % Change (24H)
                </div>
              </th>

              <th
                onClick={() => handleSort("time")}
                className="cursor-pointer pl-6 py-3 "
              >
                <div className="flex items-center justify-end whitespace-nowrap gap-1">
                  Created <FaSort />
                </div>
              </th>

              <th className="cursor-pointer px-6 py-3 whitespace-nowrap ">
                <div className="flex items-center justify-end">
                  URL
                  {/* <a href="#">
                      <svg
                        className="w-3 h-3 ms-1.5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                      </svg>
                    </a> */}
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="text-sm text-black dark:text-white font-normal">
            {data.map((item, index) => (
              <tr
                key={index}
                className="bg-white border-b dark:bg-gray-700 dark:border-gray-900"
              >
                <th
                  scope="row"
                  className={`pl-6 py-3  dark:text-white flex items-center`}
                  // style={{ minWidth: "5rem" }}
                >
                  <img
                    className="w-12 h-12 rounded-full"
                    // src="https://res.cloudinary.com/djuseai07/image/upload/v1706781066/njvqqes5lysniv5qqrbq.jpg"
                    alt="Jese image"
                    src={
                      item?.imageUrl ||
                      item?.info?.imageUrl ||
                      "https://res.cloudinary.com/djuseai07/image/upload/v1706781066/njvqqes5lysniv5qqrbq.jpg"
                    }
                    onError={(e) => {
                      e.target.src =
                        "https://res.cloudinary.com/djuseai07/image/upload/v1706781066/njvqqes5lysniv5qqrbq.jpg";
                    }}
                  />
                  <div className="mx-3 ">
                    <div className=" font-semibold line-clamp-1 flex items-center mb-1">
                      <p className="line-clamp-1">
                        {item?.name?.length > 15
                          ? item?.name?.substring(0, 15)
                          : item?.name}
                      </p>
                      <CopyToClipboard
                        text={item?.contractAddress}
                        onCopy={() => handleCopy(item?.contractAddress)}
                      >
                        <button
                          className={`ml-2 px-2 py-2 text-xs font-medium text-center ${
                            copied === item?.contractAddress
                              ? "text-white bg-blue-700"
                              : "text-blue-700 hover:text-white hover:bg-blue-700"
                          } rounded-lg focus:outline-none transition-all cursor-pointer`}
                        >
                          {copied === item?.contractAddress ? (
                            "Copied"
                          ) : (
                            <MdContentCopy />
                          )}
                        </button>
                      </CopyToClipboard>
                    </div>
                    <div className="flex items-center ">
                      {item?.liquidity?.usd > thresholdLiquidity && (
                        <span className="bg-yellow-100 text-yellow-800 text-xs font-medium px-1 py-0.5 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300 line-clamp-1">
                          Top Liquidity
                        </span>
                      )}
                    </div>
                  </div>
                </th>
                <td className="pl-6 py-4 text-right">
                  {`$${convertToMillionFormat(item?.priceUSD) || "--"}`}
                </td>
                <td className="pl-6 py-4 text-right">
                  {item?.txns[0]?.h24.buys + item?.txns[0]?.h24.sells || "--"}
                </td>

                <td className="pl-6 py-4 text-right">
                  {`$${convertToMillionFormat(item?.volume?.h24) || "--"}`}
                </td>
                <td className="pl-6 py-4 text-right">
                  {`$${convertToMillionFormat(item?.fdv) || "--"}`}
                </td>

                <td
                  className={`pl-6 py-4 text-right ${
                    item?.liquidity?.usd && "text-green-500 font-bold"
                  }`}
                >
                  {`$${convertToMillionFormat(item?.liquidity?.usd) || "--"}`}
                </td>
                <td
                  className={`pl-6 py-4 text-right ${
                    item?.priceChange?.h24 >= 0
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {item?.priceChange?.h24}%
                </td>

                <td className="pl-6 py-4 text-right">
                  {calculateTimeSince(item?.timeOfContractDeployed) || "--"}
                </td>

                <td className="px-6 py-4 text-gray-500 dark:text-white ">
                  {item?.socials.length || item?.websites.length ? (
                    <div className=" flex gap-1 justify-end items-center">
                      {item.websites?.find(
                        (social) => social.label === "OpenSea"
                      ) && (
                        <Link
                          to={
                            item.websites.find(
                              (social) => social.label === "OpenSea"
                            ).url
                          }
                        >
                          <SiOpensea size={18} style={{ fontSize: 18 }} />
                        </Link>
                      )}
                      {item.websites?.find(
                        (social) => social.label === "Website"
                      ) && (
                        <Link
                          to={
                            item.websites.find(
                              (social) => social.label === "Website"
                            ).url
                          }
                        >
                          <IoGlobeOutline size={18} style={{ fontSize: 18 }} />
                        </Link>
                      )}

                      {item.socials?.find(
                        (social) => social.type === "telegram"
                      ) && (
                        <Link
                          to={
                            item.socials.find(
                              (social) => social.type === "telegram"
                            ).url
                          }
                        >
                          <FaTelegramPlane size={18} style={{ fontSize: 18 }} />
                        </Link>
                      )}

                      {item.socials?.find(
                        (social) => social.type === "twitter"
                      ) && (
                        <Link
                          to={
                            item.socials.find(
                              (social) => social.type === "twitter"
                            ).url
                          }
                        >
                          <FaXTwitter size={18} style={{ fontSize: 18 }} />
                        </Link>
                      )}

                      {item?.chainId && (
                        <Link
                          to={`https://dexscreener.com/${item.chainId}/${item.contractAddress}`}
                          target="_blank"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill={isDarkMode ? "#fff" : "#6B7280"}
                            fill-rule="evenodd"
                            viewBox="0 0 252 300"
                            focusable="false"
                            class="chakra-icon custom-euf446 "
                          >
                            <path d="M151.818 106.866c9.177-4.576 20.854-11.312 32.545-20.541 2.465 5.119 2.735 9.586 1.465 13.193-.9 2.542-2.596 4.753-4.826 6.512-2.415 1.901-5.431 3.285-8.765 4.033-6.326 1.425-13.712.593-20.419-3.197m1.591 46.886l12.148 7.017c-24.804 13.902-31.547 39.716-39.557 64.859-8.009-25.143-14.753-50.957-39.556-64.859l12.148-7.017a5.95 5.95 0 003.84-5.845c-1.113-23.547 5.245-33.96 13.821-40.498 3.076-2.342 6.434-3.518 9.747-3.518s6.671 1.176 9.748 3.518c8.576 6.538 14.934 16.951 13.821 40.498a5.95 5.95 0 003.84 5.845zM126 0c14.042.377 28.119 3.103 40.336 8.406 8.46 3.677 16.354 8.534 23.502 14.342 3.228 2.622 5.886 5.155 8.814 8.071 7.897.273 19.438-8.5 24.796-16.709-9.221 30.23-51.299 65.929-80.43 79.589-.012-.005-.02-.012-.029-.018-5.228-3.992-11.108-5.988-16.989-5.988s-11.76 1.996-16.988 5.988c-.009.005-.017.014-.029.018-29.132-13.66-71.209-49.359-80.43-79.589 5.357 8.209 16.898 16.982 24.795 16.709 2.929-2.915 5.587-5.449 8.814-8.071C69.31 16.94 77.204 12.083 85.664 8.406 97.882 3.103 111.959.377 126 0m-25.818 106.866c-9.176-4.576-20.854-11.312-32.544-20.541-2.465 5.119-2.735 9.586-1.466 13.193.901 2.542 2.597 4.753 4.826 6.512 2.416 1.901 5.432 3.285 8.766 4.033 6.326 1.425 13.711.593 20.418-3.197"></path>
                            <path d="M197.167 75.016c6.436-6.495 12.107-13.684 16.667-20.099l2.316 4.359c7.456 14.917 11.33 29.774 11.33 46.494l-.016 26.532.14 13.754c.54 33.766 7.846 67.929 24.396 99.193l-34.627-27.922-24.501 39.759-25.74-24.231L126 299.604l-41.132-66.748-25.739 24.231-24.501-39.759L0 245.25c16.55-31.264 23.856-65.427 24.397-99.193l.14-13.754-.016-26.532c0-16.721 3.873-31.578 11.331-46.494l2.315-4.359c4.56 6.415 10.23 13.603 16.667 20.099l-2.01 4.175c-3.905 8.109-5.198 17.176-2.156 25.799 1.961 5.554 5.54 10.317 10.154 13.953 4.48 3.531 9.782 5.911 15.333 7.161 3.616.814 7.3 1.149 10.96 1.035-.854 4.841-1.227 9.862-1.251 14.978L53.2 160.984l25.206 14.129a41.926 41.926 0 015.734 3.869c20.781 18.658 33.275 73.855 41.861 100.816 8.587-26.961 21.08-82.158 41.862-100.816a41.865 41.865 0 015.734-3.869l25.206-14.129-32.665-18.866c-.024-5.116-.397-10.137-1.251-14.978 3.66.114 7.344-.221 10.96-1.035 5.551-1.25 10.854-3.63 15.333-7.161 4.613-3.636 8.193-8.399 10.153-13.953 3.043-8.623 1.749-17.689-2.155-25.799l-2.01-4.175z"></path>
                          </svg>
                        </Link>
                      )}
                    </div>
                  ) : (
                    <div className=" flex gap-1 justify-end items-center">
                      {item?.chainId && (
                        <Link
                          to={`https://dexscreener.com/${item.chainId}/${item.contractAddress}`}
                          target="_blank"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill={isDarkMode ? "#fff" : "#6B7280"}
                            fill-rule="evenodd"
                            viewBox="0 0 252 300"
                            focusable="false"
                            class="chakra-icon custom-euf446"
                          >
                            <path d="M151.818 106.866c9.177-4.576 20.854-11.312 32.545-20.541 2.465 5.119 2.735 9.586 1.465 13.193-.9 2.542-2.596 4.753-4.826 6.512-2.415 1.901-5.431 3.285-8.765 4.033-6.326 1.425-13.712.593-20.419-3.197m1.591 46.886l12.148 7.017c-24.804 13.902-31.547 39.716-39.557 64.859-8.009-25.143-14.753-50.957-39.556-64.859l12.148-7.017a5.95 5.95 0 003.84-5.845c-1.113-23.547 5.245-33.96 13.821-40.498 3.076-2.342 6.434-3.518 9.747-3.518s6.671 1.176 9.748 3.518c8.576 6.538 14.934 16.951 13.821 40.498a5.95 5.95 0 003.84 5.845zM126 0c14.042.377 28.119 3.103 40.336 8.406 8.46 3.677 16.354 8.534 23.502 14.342 3.228 2.622 5.886 5.155 8.814 8.071 7.897.273 19.438-8.5 24.796-16.709-9.221 30.23-51.299 65.929-80.43 79.589-.012-.005-.02-.012-.029-.018-5.228-3.992-11.108-5.988-16.989-5.988s-11.76 1.996-16.988 5.988c-.009.005-.017.014-.029.018-29.132-13.66-71.209-49.359-80.43-79.589 5.357 8.209 16.898 16.982 24.795 16.709 2.929-2.915 5.587-5.449 8.814-8.071C69.31 16.94 77.204 12.083 85.664 8.406 97.882 3.103 111.959.377 126 0m-25.818 106.866c-9.176-4.576-20.854-11.312-32.544-20.541-2.465 5.119-2.735 9.586-1.466 13.193.901 2.542 2.597 4.753 4.826 6.512 2.416 1.901 5.432 3.285 8.766 4.033 6.326 1.425 13.711.593 20.418-3.197"></path>
                            <path d="M197.167 75.016c6.436-6.495 12.107-13.684 16.667-20.099l2.316 4.359c7.456 14.917 11.33 29.774 11.33 46.494l-.016 26.532.14 13.754c.54 33.766 7.846 67.929 24.396 99.193l-34.627-27.922-24.501 39.759-25.74-24.231L126 299.604l-41.132-66.748-25.739 24.231-24.501-39.759L0 245.25c16.55-31.264 23.856-65.427 24.397-99.193l.14-13.754-.016-26.532c0-16.721 3.873-31.578 11.331-46.494l2.315-4.359c4.56 6.415 10.23 13.603 16.667 20.099l-2.01 4.175c-3.905 8.109-5.198 17.176-2.156 25.799 1.961 5.554 5.54 10.317 10.154 13.953 4.48 3.531 9.782 5.911 15.333 7.161 3.616.814 7.3 1.149 10.96 1.035-.854 4.841-1.227 9.862-1.251 14.978L53.2 160.984l25.206 14.129a41.926 41.926 0 015.734 3.869c20.781 18.658 33.275 73.855 41.861 100.816 8.587-26.961 21.08-82.158 41.862-100.816a41.865 41.865 0 015.734-3.869l25.206-14.129-32.665-18.866c-.024-5.116-.397-10.137-1.251-14.978 3.66.114 7.344-.221 10.96-1.035 5.551-1.25 10.854-3.63 15.333-7.161 4.613-3.636 8.193-8.399 10.153-13.953 3.043-8.623 1.749-17.689-2.155-25.799l-2.01-4.175z"></path>
                          </svg>
                        </Link>
                      )}
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {data?.map((item, ind) => (
        <div className="lg:hidden mb-4 bg-white dark:bg-gray-700">
          <div className="mx-6 flex justify-between items-center">
            <div className="flex gap-2 py-2">
              <img
                className="w-14 h-14 rounded-full"
                alt="Jese image"
                src={
                  item?.image_url ||
                  item?.info?.imageUrl ||
                  "https://res.cloudinary.com/djuseai07/image/upload/v1706781066/njvqqes5lysniv5qqrbq.jpg"
                }
                onError={(e) => {
                  e.target.src =
                    "https://res.cloudinary.com/djuseai07/image/upload/v1706781066/njvqqes5lysniv5qqrbq.jpg";
                }}
              />
              <div className="flex flex-col justify-center ">
                <div className=" flex gap-2 items-center ">
                  <div className=" font-bold text-xl dark:text-white line-clamp-1">
                    {item?.name?.length > 15
                      ? item?.name?.substring(0, 15)
                      : item?.name}
                  </div>
                  <CopyToClipboard
                    text={item?.contractAddress}
                    onCopy={() => handleCopy(item?.contractAddress)}
                  >
                    <button
                      onClick={(e) => e.stopPropagation()}
                      className={` p-2 text-xs font-medium text-center ${
                        copied === item?.contractAddress
                          ? "text-white bg-blue-700"
                          : "text-blue-700 hover:text-white hover:bg-blue-700"
                      } rounded-lg focus:outline-none transition-all cursor-pointer`}
                    >
                      {copied === item?.contractAddress ? (
                        "Copied"
                      ) : (
                        <MdContentCopy />
                      )}
                    </button>
                  </CopyToClipboard>
                </div>
                <div className="text-gray-600 dark:text-gray-400 text-base flex items-center gap-1 ">
                  {/* <p className="line-clamp-1 dark:text-white ">
                    {formatContractAddress(item?.contractAddress)}
                  </p> */}
                  <div className="flex items-center ">
                    {item?.liquidity?.usd > thresholdLiquidity && (
                      <span className="bg-yellow-100 text-yellow-800 text-xs font-medium px-1 py-0.5 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300 line-clamp-1">
                        Top Liquidity
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="dark:text-white">
              {calculateTimeSince(item?.timeOfContractDeployed) || "--"}
            </div>
          </div>
          <div className=" border-y-2 mx-6 dark:border-gray-500">
            <table className=" w-full text-left rtl:text-right text-gray-500 dark:text-white  mt-2">
              <thead className=" text-gray-700  bg-white dark:bg-gray-700 dark:text-white text-center text-base  ">
                <tr className={`  `}>
                  <th scope="col" className="py-3 px-4 text-base font-medium ">
                    <p className="text-sm">Price (USD)</p>
                    <p className="font-bold">
                      ${`${convertToMillionFormat(item?.priceUSD)}` || "..."}
                    </p>
                  </th>
                  <th scope="col" className="py-3 px-4 font-medium ho">
                    <p className="text-sm">TXN</p>
                    <p className="font-bold">
                      {item?.txns[0]?.h24?.buys + item?.txns[0]?.h24?.sells ||
                        "..."}
                    </p>
                  </th>
                  <th className="py-3 px-4 font-medium ">
                    <p className="text-sm">Volume</p>
                    <p className="font-bold">
                      ${`${convertToMillionFormat(item?.volume?.h24)}` || "..."}
                    </p>
                  </th>
                </tr>
              </thead>

              <tbody className=" text-gray-700  bg-white dark:bg-gray-700 dark:text-white text-center text-base ">
                <tr className={``}>
                  <th className="py-3 px-4  font-medium ">
                    <p className="text-sm">FDV</p>
                    <p className="font-bold">
                      ${`${convertToMillionFormat(item?.fdv)}` || "..."}
                    </p>
                  </th>
                  <th className="py-3 px-4  font-medium ">
                    <p className="text-sm">Liquidity</p>
                    <p className="font-bold">
                      {`${convertToMillionFormat(item?.liquidity?.usd)}` ||
                        "..."}
                    </p>
                  </th>
                  <th className={`py-3 px-4 font-medium e`}>
                    <p className="text-sm">24H</p>
                    <p
                      className={`font-bold ${
                        item?.priceChange?.h24 >= 0
                          ? "dark:text-green-500 text-green-600"
                          : "dark:text-red-500  text-red-600"
                      }`}
                    >
                      {item?.priceChange?.h24}%
                    </p>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="px-6 py-4 text-gray-500 dark:text-white ">
            {item?.socials.length || item?.websites.length ? (
              <div className=" flex gap-5 justify-center items-center">
                {item.websites?.find(
                  (social) => social.label === "OpenSea"
                ) && (
                  <Link
                    to={
                      item.websites.find((social) => social.label === "OpenSea")
                        .url
                    }
                  >
                    <SiOpensea size={24} style={{ fontSize: 18 }} />
                  </Link>
                )}
                {item.websites?.find(
                  (social) => social.label === "Website"
                ) && (
                  <Link
                    to={
                      item.websites.find((social) => social.label === "Website")
                        .url
                    }
                  >
                    <IoGlobeOutline size={24} style={{ fontSize: 18 }} />
                  </Link>
                )}

                {item.socials?.find((social) => social.type === "telegram") && (
                  <Link
                    to={
                      item.socials.find((social) => social.type === "telegram")
                        .url
                    }
                  >
                    <FaTelegramPlane size={24} style={{ fontSize: 18 }} />
                  </Link>
                )}

                {item.socials?.find((social) => social.type === "twitter") && (
                  <Link
                    to={
                      item.socials.find((social) => social.type === "twitter")
                        .url
                    }
                  >
                    <FaXTwitter size={24} style={{ fontSize: 18 }} />
                  </Link>
                )}

                {item?.chainId && (
                  <Link
                    to={`https://dexscreener.com/${item.chainId}/${item.contractAddress}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill={isDarkMode ? "#fff" : "#6B7280"}
                      fill-rule="evenodd"
                      viewBox="0 0 252 300"
                      focusable="false"
                      class="chakra-icon custom-euf446 "
                    >
                      <path d="M151.818 106.866c9.177-4.576 20.854-11.312 32.545-20.541 2.465 5.119 2.735 9.586 1.465 13.193-.9 2.542-2.596 4.753-4.826 6.512-2.415 1.901-5.431 3.285-8.765 4.033-6.326 1.425-13.712.593-20.419-3.197m1.591 46.886l12.148 7.017c-24.804 13.902-31.547 39.716-39.557 64.859-8.009-25.143-14.753-50.957-39.556-64.859l12.148-7.017a5.95 5.95 0 003.84-5.845c-1.113-23.547 5.245-33.96 13.821-40.498 3.076-2.342 6.434-3.518 9.747-3.518s6.671 1.176 9.748 3.518c8.576 6.538 14.934 16.951 13.821 40.498a5.95 5.95 0 003.84 5.845zM126 0c14.042.377 28.119 3.103 40.336 8.406 8.46 3.677 16.354 8.534 23.502 14.342 3.228 2.622 5.886 5.155 8.814 8.071 7.897.273 19.438-8.5 24.796-16.709-9.221 30.23-51.299 65.929-80.43 79.589-.012-.005-.02-.012-.029-.018-5.228-3.992-11.108-5.988-16.989-5.988s-11.76 1.996-16.988 5.988c-.009.005-.017.014-.029.018-29.132-13.66-71.209-49.359-80.43-79.589 5.357 8.209 16.898 16.982 24.795 16.709 2.929-2.915 5.587-5.449 8.814-8.071C69.31 16.94 77.204 12.083 85.664 8.406 97.882 3.103 111.959.377 126 0m-25.818 106.866c-9.176-4.576-20.854-11.312-32.544-20.541-2.465 5.119-2.735 9.586-1.466 13.193.901 2.542 2.597 4.753 4.826 6.512 2.416 1.901 5.432 3.285 8.766 4.033 6.326 1.425 13.711.593 20.418-3.197"></path>
                      <path d="M197.167 75.016c6.436-6.495 12.107-13.684 16.667-20.099l2.316 4.359c7.456 14.917 11.33 29.774 11.33 46.494l-.016 26.532.14 13.754c.54 33.766 7.846 67.929 24.396 99.193l-34.627-27.922-24.501 39.759-25.74-24.231L126 299.604l-41.132-66.748-25.739 24.231-24.501-39.759L0 245.25c16.55-31.264 23.856-65.427 24.397-99.193l.14-13.754-.016-26.532c0-16.721 3.873-31.578 11.331-46.494l2.315-4.359c4.56 6.415 10.23 13.603 16.667 20.099l-2.01 4.175c-3.905 8.109-5.198 17.176-2.156 25.799 1.961 5.554 5.54 10.317 10.154 13.953 4.48 3.531 9.782 5.911 15.333 7.161 3.616.814 7.3 1.149 10.96 1.035-.854 4.841-1.227 9.862-1.251 14.978L53.2 160.984l25.206 14.129a41.926 41.926 0 015.734 3.869c20.781 18.658 33.275 73.855 41.861 100.816 8.587-26.961 21.08-82.158 41.862-100.816a41.865 41.865 0 015.734-3.869l25.206-14.129-32.665-18.866c-.024-5.116-.397-10.137-1.251-14.978 3.66.114 7.344-.221 10.96-1.035 5.551-1.25 10.854-3.63 15.333-7.161 4.613-3.636 8.193-8.399 10.153-13.953 3.043-8.623 1.749-17.689-2.155-25.799l-2.01-4.175z"></path>
                    </svg>
                  </Link>
                )}
              </div>
            ) : (
              <div className=" flex  justify-center gap-5 items-center">
                {item?.chainId && (
                  <Link
                    to={`https://dexscreener.com/${item.chainId}/${item.contractAddress}`}
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill={isDarkMode ? "#fff" : "#6B7280"}
                      fill-rule="evenodd"
                      viewBox="0 0 252 300"
                      focusable="false"
                      class="chakra-icon custom-euf446"
                    >
                      <path d="M151.818 106.866c9.177-4.576 20.854-11.312 32.545-20.541 2.465 5.119 2.735 9.586 1.465 13.193-.9 2.542-2.596 4.753-4.826 6.512-2.415 1.901-5.431 3.285-8.765 4.033-6.326 1.425-13.712.593-20.419-3.197m1.591 46.886l12.148 7.017c-24.804 13.902-31.547 39.716-39.557 64.859-8.009-25.143-14.753-50.957-39.556-64.859l12.148-7.017a5.95 5.95 0 003.84-5.845c-1.113-23.547 5.245-33.96 13.821-40.498 3.076-2.342 6.434-3.518 9.747-3.518s6.671 1.176 9.748 3.518c8.576 6.538 14.934 16.951 13.821 40.498a5.95 5.95 0 003.84 5.845zM126 0c14.042.377 28.119 3.103 40.336 8.406 8.46 3.677 16.354 8.534 23.502 14.342 3.228 2.622 5.886 5.155 8.814 8.071 7.897.273 19.438-8.5 24.796-16.709-9.221 30.23-51.299 65.929-80.43 79.589-.012-.005-.02-.012-.029-.018-5.228-3.992-11.108-5.988-16.989-5.988s-11.76 1.996-16.988 5.988c-.009.005-.017.014-.029.018-29.132-13.66-71.209-49.359-80.43-79.589 5.357 8.209 16.898 16.982 24.795 16.709 2.929-2.915 5.587-5.449 8.814-8.071C69.31 16.94 77.204 12.083 85.664 8.406 97.882 3.103 111.959.377 126 0m-25.818 106.866c-9.176-4.576-20.854-11.312-32.544-20.541-2.465 5.119-2.735 9.586-1.466 13.193.901 2.542 2.597 4.753 4.826 6.512 2.416 1.901 5.432 3.285 8.766 4.033 6.326 1.425 13.711.593 20.418-3.197"></path>
                      <path d="M197.167 75.016c6.436-6.495 12.107-13.684 16.667-20.099l2.316 4.359c7.456 14.917 11.33 29.774 11.33 46.494l-.016 26.532.14 13.754c.54 33.766 7.846 67.929 24.396 99.193l-34.627-27.922-24.501 39.759-25.74-24.231L126 299.604l-41.132-66.748-25.739 24.231-24.501-39.759L0 245.25c16.55-31.264 23.856-65.427 24.397-99.193l.14-13.754-.016-26.532c0-16.721 3.873-31.578 11.331-46.494l2.315-4.359c4.56 6.415 10.23 13.603 16.667 20.099l-2.01 4.175c-3.905 8.109-5.198 17.176-2.156 25.799 1.961 5.554 5.54 10.317 10.154 13.953 4.48 3.531 9.782 5.911 15.333 7.161 3.616.814 7.3 1.149 10.96 1.035-.854 4.841-1.227 9.862-1.251 14.978L53.2 160.984l25.206 14.129a41.926 41.926 0 015.734 3.869c20.781 18.658 33.275 73.855 41.861 100.816 8.587-26.961 21.08-82.158 41.862-100.816a41.865 41.865 0 015.734-3.869l25.206-14.129-32.665-18.866c-.024-5.116-.397-10.137-1.251-14.978 3.66.114 7.344-.221 10.96-1.035 5.551-1.25 10.854-3.63 15.333-7.161 4.613-3.636 8.193-8.399 10.153-13.953 3.043-8.623 1.749-17.689-2.155-25.799l-2.01-4.175z"></path>
                    </svg>
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AlphaPage;
