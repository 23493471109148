import { React, useState, useEffect, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdContentCopy, MdCheck } from "react-icons/md";
import { IoIosArrowRoundBack } from "react-icons/io";
import NftCard from "../components/NftCard";
import Progress from "../components/Progress";
import "../App.css";
import axios from "axios";
import { convertToMillionFormat, formatContractAddress } from "../utils/utils";

const ShowCollectionsPage = () => {
  const navigate = useNavigate(); // to redirect to another url
  const { collectionsData, logoSource, isDarkMode } = useContext(AppContext); // has collection data of all the collections
  const { collectionName } = useParams(); // obtain the collection slug from url
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [activeTab, setActiveTab] = useState("m5"); //active tab for the transaction panel
  const [copied, setCopied] = useState(false);
  const [pageSize] = useState(15); //size of page (15 nfts per page)
  const [currentPage, setCurrentPage] = useState(0);
  const [newPages, setNewPages] = useState([]);

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  // Transaction Details Panel
  const renderPanelContent = () => {
    const txns = selectedCollection.txns[0];

    switch (activeTab) {
      case "m5":
      case "h1":
      case "h6":
      case "h24":
        return (
          <>
            {/* Progress Bar */}
            <Progress
              buys={txns[activeTab].buys}
              sells={txns[activeTab].sells}
            />
          </>
        );
      default:
        return null;
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    // filtering data of a particular collection based on collection slug
    const filteredData = collectionsData.filter(
      (item) => item?.collection_slug === collectionName
    );
    setSelectedCollection(filteredData[0]);
  }, [collectionsData]);

  // making an api end call when the user clicks on previous or next pagination button
  useEffect(() => {
    const fetchNFTs = async () => {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_BACKEND
          }/app/nftByCollection?collectionName=${collectionName}&pageSize=${pageSize}&offset=${
            (currentPage + 1) * pageSize
          }`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch NFTs");
        }

        const data = await response.json();
        setNewPages(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchNFTs();
  }, [currentPage]);

  return (
    <div className="flex justify-center dark:bg-gray-900 bg-[#f6f6f6] ">
      <div className="flex-col items-start mx-2 md:mx-32">
        <div className="flex dark:bg-gray-700 justify-between">
          {/* if the collection is empty navigate to home */}
          {!selectedCollection ? (
            navigate("/")
          ) : (
            <div className="py-4 w-full flex flex-col md:flex-row gap-4 dark:bg-gray-900 relative">
              {/* Left Panel */}
              <div
                style={{ borderRadius: "30px" }}
                className="w-full md:w-[32rem] p-4 text-gray-800 bg-white dark:text-white dark:bg-gray-700 h-fit  top-[7rem] md:sticky"
              >
                <div className="flex items-center gap-2 my-4 mt-2">
                  <IoIosArrowRoundBack
                    size={48}
                    style={{
                      backgroundColor: `${isDarkMode ? "#312F57" : "#ddd6fe"}`,
                      color: `${isDarkMode ? "#ddd6fe" : "#6d28d9"}`,
                      borderRadius: "50%",
                      padding: "5px", // Adjust the padding value as needed
                      cursor: "pointer",
                    }}
                    //goes to the previous page (home)
                    onClick={() => navigate(-1)}
                  />
                  <span className="flex flex-col items-start ">
                    <h2 className="text-xl font-bold text-center dark:text-white">
                      ERC404 Collections
                    </h2>

                    {/* Breadcrumb */}
                    <nav class="flex" aria-label="Breadcrumb">
                      <ol class="flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                        <li class="inline-flex items-center">
                          <Link to={"/"}>
                            <a class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                              Home
                            </a>
                          </Link>
                        </li>

                        <li aria-current="page">
                          <div class="flex items-center">
                            <svg
                              class="rtl:rotate-180  w-3 h-3 mx-1 text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 6 10"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m1 9 4-4-4-4"
                              />
                            </svg>

                            <span class="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">
                              {selectedCollection?.name}
                            </span>
                          </div>
                        </li>
                      </ol>
                    </nav>
                  </span>
                </div>

                <div className="mb-4 flex-wrap">
                  <div className="flex flex-col items-center justify-center gap-1 mb-2">
                    <img
                      src={
                        selectedCollection?.image_url ||
                        "https://res.cloudinary.com/djuseai07/image/upload/v1706781066/njvqqes5lysniv5qqrbq.jpg"
                      }
                      className="rounded-full w-12 h-12 object-cover object-center"
                    />
                    <h2 className="text-xl font-bold text-center">
                      {selectedCollection?.name}
                    </h2>
                  </div>
                  <div
                    className={`text-gray-600 dark:text-gray-400 text-lg mb-4 flex flex-wrap justify-center items-center gap-2`}
                  >
                    <p>
                      {selectedCollection?.chain.charAt(0).toUpperCase() +
                        selectedCollection?.chain.slice(1)}
                    </p>
                    {logoSource[selectedCollection?.chain] && (
                      <img
                        className="rounded-full w-6 h-6 object-cover object-center"
                        src={logoSource[selectedCollection?.chain]}
                      />
                    )}
                  </div>

                  {/* Token Address Clipboard */}
                  <div class=" w-full max-w-sm mb-2">
                    <div class="flex items-center justify-center">
                      <div
                        class="flex-shrink-0 z-10  items-center py-2 px-4 border-0 text-sm font-medium text-center text-white bg-[#525259] dark:bg-[#4F46E5]  rounded-s-lg rounded-e-lg "
                        style={{
                          cursor: "default",
                        }}
                      >
                        Token Address
                      </div>
                      <div class="relative w-full dark:bg-gray-800">
                        <span className="line-clamp-1 dark:bg-gray-800 border border-e-0 border-gray-300 text-gray-500 dark:text-gray-400 text-sm border-s-0 focus:ring-blue-500 focus:border-blue-500 block w-full p-2  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 text-center">
                          {formatContractAddress(
                            selectedCollection?.contractAddress
                          )}
                        </span>
                      </div>
                      <CopyToClipboard
                        text={selectedCollection?.contractAddress}
                        onCopy={handleCopy}
                      >
                        <button
                          class="flex-shrink z-10 inline-flex items-center py-2.5 px-2 text-sm font-medium text-center text-gray-500 dark:text-gray-400 hover:text-gray-900 bg-gray-100 border border-gray-300 rounded-e-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:hover:text-white dark:border-gray-600"
                          type="button"
                        >
                          {copied ? (
                            <MdCheck className="text-lg" />
                          ) : (
                            <MdContentCopy className="text-lg" />
                          )}
                        </button>
                      </CopyToClipboard>
                    </div>
                  </div>

                  {/* Price, TXN, Liquidity, FDV Card */}

                  <div className="grid grid-cols-2 md:grid-cols-2 mb-2 text-lg">
                    <div className="flex flex-col items-center p-2 border-r border-t border-gray-300 text-gray-800 dark:text-white dark:bg-gray-700">
                      <h2 className="flex text-base text-center font-bold text-gray-800 dark:text-white dark:bg-gray-700">
                        Price (USD)
                      </h2>
                      <p className="font-bold text-indigo-600  dark:text-[#E7E5FF] dark:bg-gray-700 text-sm">
                        $ {convertToMillionFormat(selectedCollection?.priceUSD)}
                      </p>
                    </div>

                    <div className="flex flex-col items-center p-2 border-t border-gray-300 text-gray-800 dark:text-white dark:bg-gray-700">
                      <h2 className="flex text-base text-center font-bold text-gray-800 dark:text-white dark:bg-gray-700">
                        TXNS (24H)
                      </h2>
                      <p className="font-bold text-indigo-600  dark:text-[#E7E5FF] dark:bg-gray-700 text-sm">
                        {selectedCollection?.txns[0]?.h24?.buys +
                          selectedCollection?.txns[0]?.h24?.sells}
                      </p>
                    </div>

                    <div className="flex flex-col items-center p-2 border-t border-b border-r border-gray-300 text-gray-800 dark:text-white dark:bg-gray-700">
                      <h2 className="flex text-base text-center font-bold text-gray-800 dark:text-white dark:bg-gray-700">
                        Liquidity
                      </h2>
                      <p className="font-bold dark:text-green-500 text-green-600 dark:bg-gray-700 text-sm">
                        ${" "}
                        {convertToMillionFormat(
                          selectedCollection?.liquidity?.usd
                        )}
                      </p>
                    </div>

                    <div className="flex flex-col items-center p-2 border-t border-b border-gray-300 text-gray-800 dark:text-white dark:bg-gray-700">
                      <h2 className="flex text-sm text-center font-bold text-gray-800 dark:text-white dark:bg-gray-700">
                        FDV
                      </h2>
                      <p className="font-bold dark:text-red-500 text-red-600 dark:bg-gray-700 text-sm">
                        $ {convertToMillionFormat(selectedCollection?.fdv)}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Table for Transaction Details */}
                <div className="mt-8  text-base">
                  <span className="flex  flex-wrap justify-between gap-1">
                    <h2
                      style={{ fontSize: "1.05rem" }}
                      className="font-bold text-gray-800 dark:text-white mb-2"
                    >
                      Transaction Details
                    </h2>

                    <div className="mb-4 flex gap-1">
                      {/* Tabs */}
                      <button
                        onClick={() => handleTabClick("m5")}
                        className={`tab-btn w-1/4 px-2 border text-base border-indigo-600 text-gray-800 dark:text-white  rounded-md ${
                          activeTab === "m5" &&
                          "active-tab text-white  bg-indigo-600 dark:bg-indigo-600"
                        }`}
                      >
                        5M
                      </button>
                      <button
                        onClick={() => handleTabClick("h1")}
                        className={`tab-btn w-1/4 px-2 border text-base border-indigo-600 text-gray-800 dark:text-white rounded-md ${
                          activeTab === "h1" &&
                          "active-tab  text-white bg-indigo-600 dark:bg-indigo-600"
                        }`}
                      >
                        1H
                      </button>
                      <button
                        onClick={() => handleTabClick("h6")}
                        className={`tab-btn w-1/4 px-2 border text-base border-indigo-600 text-gray-800 dark:text-white  rounded-md ${
                          activeTab === "h6" &&
                          "active-tab text-white bg-indigo-600 dark:bg-indigo-600"
                        }`}
                      >
                        6H
                      </button>
                      <button
                        onClick={() => handleTabClick("h24")}
                        className={`tab-btn w-1/4 px-2 border text-base border-indigo-600 text-gray-800 dark:text-white rounded-md ${
                          activeTab === "h24" &&
                          "active-tab text-white bg-indigo-600 dark:bg-indigo-600"
                        }`}
                      >
                        24H
                      </button>
                    </div>
                  </span>
                  {/* Panel content */}
                  {renderPanelContent()}
                </div>
              </div>

              {/* Right Panel (3/4 width) for NFTs */}
              <div
                style={{ borderRadius: "30px" }}
                className="w-full md:flex-grow p-4 bg-white text-gray-800 dark:text-white dark:bg-gray-700 mt-1"
              >
                <div className="flex flex-col lg:flex-row justify-between px-4 gap-4 mb-4  ">
                  <div className="flex items-center gap-4">
                    <div>
                      <h2 className="text-lg font-bold">
                        {selectedCollection?.name}
                      </h2>
                      <p className="flex text-gray-600 dark:text-gray-300 items-center">
                        {selectedCollection?.collection_slug}
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-cols-4  lg:grid-cols-6 gap-1 items-center justify-center">
                    <div className="flex flex-col items-end p-2 px-3   border-r border-gray-300 text-gray-800 dark:text-white dark:bg-gray-700 ">
                      <h2 className="flex text-sm sm:text-base font-bold text-gray-800 dark:text-white dark:bg-gray-700">
                        Floor
                      </h2>
                      <div className="flex items-end">
                        <p
                          className={`font-bold text-xs sm:text-sm text-gray-800 dark:text-white dark:bg-gray-700 line-clamp-1`}
                        >
                          {selectedCollection?.priceNative
                            ? parseFloat(
                                selectedCollection.priceNative
                              ).toFixed(2)
                            : "N/A"}{" "}
                          WETH
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-end p-2 px-3  border-r border-gray-300 text-gray-800 dark:text-white dark:bg-gray-700 ">
                      <h2 className="flex text-sm sm:text-base font-bold text-gray-800 dark:text-white dark:bg-gray-700">
                        Supply
                      </h2>
                      <p
                        className={`font-bold text-xs sm:text-sm text-gray-800 dark:text-white dark:bg-gray-700`}
                      >
                        {selectedCollection?.totalSupply}
                      </p>
                    </div>
                    <div className="hidden lg:flex flex-col items-end p-2 px-3  border-r border-gray-300 text-gray-800 dark:text-white dark:bg-gray-700 ">
                      <h2 className="flex text-sm sm:text-base  font-bold text-gray-800 dark:text-white dark:bg-gray-700">
                        5M
                      </h2>
                      <p
                        className={`font-bold text-xs sm:text-sm ${
                          selectedCollection?.priceChange?.m5 >= 0
                            ? "dark:text-green-500 text-green-600"
                            : "dark:text-red-500  text-red-600"
                        }`}
                      >
                        {selectedCollection?.priceChange?.m5}%
                      </p>
                    </div>

                    <div className="flex flex-col items-end p-2 px-3  border-r border-gray-300 text-gray-800 dark:text-white dark:bg-gray-700 ">
                      <h2 className="flex text-sm sm:text-base  font-bold text-gray-800 dark:text-white dark:bg-gray-700">
                        1H
                      </h2>
                      <p
                        className={`font-bold text-xs sm:text-sm ${
                          selectedCollection?.priceChange?.h1 >= 0
                            ? "dark:text-green-500 text-green-600"
                            : "dark:text-red-500  text-red-600"
                        }`}
                      >
                        {selectedCollection?.priceChange?.h1}%
                      </p>
                    </div>

                    <div className=" hidden lg:flex flex-col items-end p-2 px-3  border-r border-gray-300 text-gray-800 dark:text-white dark:bg-gray-700 ">
                      <h2 className="flex text-sm sm:text-base  font-bold text-gray-800 dark:text-white dark:bg-gray-700">
                        6H
                      </h2>
                      <p
                        className={`font-bold text-xs sm:text-sm ${
                          selectedCollection?.priceChange?.h6 >= 0
                            ? "dark:text-green-500 text-green-600"
                            : "dark:text-red-500  text-red-600"
                        }`}
                      >
                        {selectedCollection?.priceChange?.h6}%
                      </p>
                    </div>

                    <div className="flex flex-col items-end p-2 px-3   border-gray-300 text-gray-800 dark:text-white dark:bg-gray-700 ">
                      <h2 className="flex text-sm sm:text-base font-bold text-gray-800 dark:text-white dark:bg-gray-700">
                        24H
                      </h2>
                      <p
                        className={`font-bold text-xs sm:text-sm ${
                          selectedCollection?.priceChange?.h24 >= 0
                            ? "dark:text-green-500 text-green-600"
                            : "dark:text-red-500  text-red-600"
                        }`}
                      >
                        {selectedCollection?.priceChange?.h24}%
                      </p>
                    </div>
                  </div>
                </div>

                {/* NFT Display */}
                <div className="flex justify-center w-full">
                  {/* Remaining NFTs obtained through pagination*/}
                  {newPages.length ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2  lg:flex flex-wrap  gap-4 justify-center place-content-center">
                      {newPages?.map((item, i) => (
                        <NftCard nftData={item} key={i} />
                      ))}
                    </div>
                  ) : (
                    <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:flex flex-wrap gap-4 justify-center place-content-center">
                      {/* First 15 NFTs */}
                      {selectedCollection?.nfts?.map((item, i) => (
                        <NftCard nftData={item} key={i} />
                      ))}
                    </div>
                  )}
                </div>

                {/* Pagination Buttons */}

                <nav className="flex items-center justify-center mt-4">
                  <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 0}
                    className="flex items-center justify-center px-4 h-10 text-base font-medium text-gray-500 bg-white border border-gray-300  rounded-s hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    Prev
                  </button>
                  <ul className="flex items-center space-x-4">
                    <button
                      disabled={true}
                      className={`px-3 h-10 leading-tight text-base font-medium text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                    >
                      {currentPage}
                    </button>
                  </ul>

                  <button
                    onClick={handleNextPage}
                    className="flex items-center justify-center px-4 h-10 text-base font-medium text-gray-500 bg-white border border-gray-300  rounded-e hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    Next
                  </button>
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShowCollectionsPage;
