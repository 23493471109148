import React, { useContext } from "react";
import { useWeb3Modal } from "@web3modal/ethers5/react";
import { FaTelegramPlane } from "react-icons/fa";
import { IoCloseCircleSharp, IoCheckmarkCircleSharp } from "react-icons/io5";
import ManageApiKeys from "../components/ManageApiKeys"; // Import your actual component for managing API keys
import { handleRedirect } from "../utils/utils";
import { AppContext } from "../context/AppContext";

const ApiKeyPage = () => {
  const { subscribe, keyVerified, apikey } = useContext(AppContext);

  return (
    <div className="bg-white  dark:bg-gray-800 flex-grow md:rounded-[30px]   md:shadow-lg md:my-4 h-100 ">
      <div className={`w-100 bg-white dark:bg-gray-800 px-3 md:rounded-[30px]`}>
        {subscribe === "Subscribed" && <ManageApiKeys apikey={apikey} />}

        <p className="text-base text-gray-900 dark:text-white my-4 mx-8 font-bold">
          {subscribe !== "" ? (
            subscribe === "Subscribed" ? (
              <div className="flex items-center gap-2">
                <IoCheckmarkCircleSharp
                  className="flex-shrink-0 w-6 h-6 md:w-7 md:h-7 py-1"
                  style={{ color: "#16a34a" }}
                />{" "}
                <span style={{ color: "#16a34a" }}> User Subscribed.</span>
              </div>
            ) : (
              <div className="flex items-start gap-2">
                <IoCloseCircleSharp
                  className="flex-shrink-0 w-6 h-6 md:w-7 md:h-7 py-1"
                  style={{ color: "#dc2626" }}
                />{" "}
                <span>
                  <span className="text-[#dc2626]">User Not Subscribed. </span>
                  Unlock your subscription by minting at least one NFT in the
                  DODAS Collection.{" "}
                  <a
                    className="text-blue-600 "
                    href="https://opensea.io/collection/dodas-erc404-pass/overview"
                    target="_blank"
                  >
                    Click here
                  </a>
                </span>
              </div>
            )
          ) : (
            <div></div>
          )}
        </p>

        {subscribe === "Subscribed" && (
          <>
            <p className="text-base text-gray-900 dark:text-white my-4 mx-8 font-bold">
              {keyVerified !== "" ? (
                keyVerified === "Verified" ? (
                  <>
                    <div className="flex items-center gap-2">
                      <IoCheckmarkCircleSharp
                        className="flex-shrink-0 w-6 h-6 md:w-7 md:h-7 py-1"
                        style={{ color: "#16a34a" }}
                      />{" "}
                      <span style={{ color: "#16a34a" }}> User Verified</span>
                    </div>
                  </>
                ) : (
                  <div className="flex items-start gap-2">
                    <IoCloseCircleSharp
                      className="flex-shrink-0 w-6 h-6 md:w-7 md:h-7 py-1"
                      style={{ color: "#dc2626" }}
                    />{" "}
                    <span>
                      <span className="text-[#dc2626]">
                        User Not Verified.{" "}
                      </span>
                      Please Click on the Button below to verify and access the
                      telegram bot
                    </span>
                  </div>
                )
              ) : (
                <div></div>
              )}
            </p>
            <button
              onClick={() => handleRedirect("https://t.me/ERC404_DodasBot")}
              className={`mt-4 mx-8 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none gap-2 flex items-center `}
            >
              <span className="text-sm md:text-base lg:text-base">
                {keyVerified === "Verified"
                  ? "Telegram Bot"
                  : "Connect With Telegram"}
              </span>
              <FaTelegramPlane size={24} style={{ fontSize: 24 }} />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ApiKeyPage;
