import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AppContextProvider } from "./context/AppContext";
import { AuthProvider } from "@arcana/auth"; //From npm
import { ProvideAuth } from "@arcana/auth-react";

const provider = new AuthProvider(
  "xar_live_775ff22c836620170ce9a84ee62c6b0bc8b2cedc", // App client ID
  {
    position: "left", // default: right
    theme: "dark", // default: dark
    alwaysVisible: false, // default: true, wallet always visible
    setWindowProvider: true, // default: false, window.ethereum not set
    connectOptions: {
      compact: true, // default: false, regular plug-and-play login UI
    },
    chainConfig: {
      chainId: "137", // defaults to Ethereum
      rpcUrl: "https://polygon-rpc.com", // defaults to 'https://rpc.ankr.com/eth'
    },
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AppContextProvider>
      <ProvideAuth provider={provider}>
        <App />
      </ProvideAuth>
    </AppContextProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
