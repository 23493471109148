import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ModalBox from "./ModalBox";
import logo from "../assets/DarkLogo 2.png";
import logo2 from "../assets/dodasLogo 3.png";
import { AppContext } from "../context/AppContext";
import LoginPage from "../pages/LoginPage";
import { FaTelegramPlane } from "react-icons/fa";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const { setSearchQuery, isModalOpen, setIsModalOpen, isDarkMode, subscribe } =
    useContext(AppContext);
  const logoSource = isDarkMode ? logo2 : logo;

  const handleInputChange = (event) => {
    navigate("/search");
    setInputValue(event.target.value);
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  useEffect(() => {
    const timerId = setTimeout(() => {
      setSearchQuery(inputValue);
    }, 500);

    // Clear the timer if the user types again within the delay
    return () => clearTimeout(timerId);
  }, [inputValue]);

  return (
    <nav className="sticky top-0 z-50  bg-white border-gray-200 dark:bg-gray-900 drop-shadow-lg">
      <div className=" flex flex-wrap items-center justify-between  lg:mx-32 mx-6 py-4 ">
        {/* header name */}
        <div className="flex justify-between">
          <div className="">
            <Link
              to="/"
              className="flex items-center space-x-3 rtl:space-x-reverse"
            >
              <img src={logoSource} className="h-8" alt="Dodas Logo" />
              <span className="dark:text-white font-extrabold">BETA</span>
            </Link>
            <p className="dark:text-white  font-bold p-1"> ERC404 Indexer</p>
          </div>
        </div>
        <div className="relative hidden md:flex md:items-center ">
          <div className="absolute  inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
            <span className="sr-only">Search icon</span>
          </div>
          <input
            type="text"
            id="search-navbar"
            value={inputValue}
            onChange={handleInputChange}
            className="block md:w-[20rem]  lg:w-[30rem] p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search NFT / Inscription"
          />
        </div>

        {/* hamburger Icon  */}
        <div className="flex md:order-2 md:hidden">
          <a
            href="https://t.me/ERC404_DodasBot"
            target="blank"
            class="text-white md:hidden bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2 text-center inline-flex items-center me-2 dark:bg-blue-500 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <FaTelegramPlane size={24} style={{ fontSize: 24 }} />

            <span className="sr-only">Search</span>
          </a>

          <button
            data-collapse-toggle="navbar-search"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-search"
            aria-expanded="false"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>

        {/* hidden menus */}
        <div
          className={`md:items-center md:justify-between w-full md:flex md:w-auto md:order-1  ${
            isOpen ? "" : "hidden"
          }`}
          id="navbar-search"
        >
          <div className="relative mt-3 md:hidden">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="search-navbar2"
              value={inputValue}
              onChange={handleInputChange}
              className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search NFT / Inscription"
            />
          </div>
          <ul className="flex flex-col md:items-center p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-6 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li>
              <a
                href="https://erc404docs.dodas.xyz/"
                className="font-extrabold text-sm block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                target="_blank"
              >
                API
              </a>
            </li>
            <li>
              <Link
                to="/alpha"
                className="font-extrabold text-sm block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >
                Alpha✨
              </Link>
            </li>

            {subscribe === "Subscribed" && (
              <li>
                <Link
                  onClick={toggleModal}
                  className="font-extrabold text-sm block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  +ERC404 NFT
                </Link>
                {isModalOpen && <ModalBox toggleModal={toggleModal} />}
              </li>
            )}
            <LoginPage />
            <li className="hidden md:block lg:block">
              <a
                href="https://t.me/ERC404_DodasBot"
                target="blank"
                class="text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2 text-center inline-flex items-center  dark:bg-blue-500 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                <FaTelegramPlane size={24} style={{ fontSize: 24 }} />

                <span className="sr-only">Search</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
