import React, { useContext, useEffect, useState } from "react";
import NFTTable from "../components/NFTTable";
import { AppContext } from "../context/AppContext";
import axios from "axios";
import { compareByFDV } from "../utils/utils";

const HomePage = () => {
  const { collectionsData, setCollectionsData, setAllChains, allChains } =
    useContext(AppContext);
  const [selectedChain, setSelectedChain] = useState("");
  const [filteredArray, setFilteredArray] = useState(collectionsData);

  // function that handles changing the chain for filtering
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedChain(selectedValue);

    const filtered = collectionsData.filter(
      (obj) => obj.chain === selectedValue
    );
    setFilteredArray(filtered);
  };

  useEffect(() => {
    const objectsWithChain = collectionsData.filter(
      (obj) => obj.chain !== undefined
    );

    // Extract unique chains
    const chains = [...new Set(objectsWithChain.map((obj) => obj.chain))];
    setAllChains(chains);
  }, [collectionsData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND}/app/collections`
        );

        const newdata = data.map((item) => {
          let obj = item.collection;
          obj.nfts = item.nft;
          return obj;
        });

        newdata.sort(compareByFDV);
        setCollectionsData(newdata);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex-grow dark:bg-gray-900 bg-[#f6f6f6] ">
      <div className="mx-6 my-12 md:flex md:justify-between md:items-center lg:flex lg:justify-between lg:mx-32 lg:items-center ">
        <p className="text-3xl text-gray-900 dark:text-white  font-bold px-5 py-4 border-l-8 border-indigo-600">
          ERC404 Collections:
        </p>

        <div className=" flex flex-col gap-2 w-fit mt-2 md:flex-row md:items-center lg:flex-row lg:items-center ">
          <span
            htmlFor="countries"
            className="text-sm font-bold text-gray-900 dark:text-white w-fit"
          >
            Filter by Chain
          </span>
          <select
            value={selectedChain}
            onChange={handleSelectChange}
            id="countries"
            className="bg-gray-50 border min-w-[15rem] border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option selected value="">
              All
            </option>
            {allChains?.map((chain, index) => (
              <option key={index} value={chain}>
                {chain?.charAt(0).toUpperCase() + chain?.slice(1)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="lg:mx-32">
        <NFTTable
          collectionsData={selectedChain ? filteredArray : collectionsData}
        />
      </div>
    </div>
  );
};

export default HomePage;
