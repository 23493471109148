import React, { useContext } from "react";
import NFTTable from "../components/NFTTable";
import { AppContext } from "../context/AppContext";
import NftCard from "../components/NftCard";

const SearchPage = () => {
  const { receivedData, receivedNFT } = useContext(AppContext);
  return (
    <div className="flex-grow dark:bg-gray-900 bg-[#f6f6f6]  ">
      <div className="lg:mx-32 ">
        <p className="text-xl text-gray-900 dark:text-white my-4 font-bold px-5 py-2  border-l-8 border-indigo-600">
          ERC404 Collections Results:
        </p>
        {receivedData.length ? (
          <NFTTable collectionsData={receivedData} />
        ) : (
          <div className="relative  shadow-md sm:rounded-lg my-4  ">
            <div className="w-full bg-white b dark:bg-gray-700 dark:border-gray-500  ">
              <div className="px-6 py-4 dark:text-white">
                No Collections Found
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="lg:mx-32 ">
        <p className="text-xl text-gray-900 dark:text-white my-4 font-bold px-5 py-2  border-l-8 border-indigo-600 ">
          NFT Results:
        </p>
        <div className="relative  shadow-md sm:rounded-lg my-4  ">
          <div className="w-full bg-white  dark:bg-gray-700 dark:border-gray-500  ">
            <div className="flex  px-4 py-3 overflow-x-auto gap-2 ">
              {receivedNFT.length ? (
                receivedNFT?.map(
                  (item, i) => i < 5 && <NftCard nftData={item} key={i} />
                )
              ) : (
                <p className="dark:text-white">No NFTs Found</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
