import "./App.css";
import Header from "./components/Header";
import { useContext, useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useSearchParams,
} from "react-router-dom";
import SearchPage from "./pages/SearchPage";
import HomePage from "./pages/HomePage";
import { AppContext } from "./context/AppContext";
import axios from "axios";
import ShowCollectionsPage from "./pages/ShowCollectionsPage";
import AlphaPage from "./pages/AlphaPage";
import ProfilePage from "./pages/ProfilePage";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import { IoSunny, IoMoon } from "react-icons/io5";
import styles from "./assets/Themetogglebutton.module.css"; // Import a separate CSS module for styles
import { getCookie, setCookie } from "./utils/utils";
import { ToastContainer, toast } from "react-toastify";

// 1. Get projectId
const projectId = "d7eefb708c4a8fde80e0ccbd36aeea17";

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://cloudflare-eth.com",
};
const sepolia = {
  chainId: 11155111,
  name: "Sepolia",
  currency: "ETH", // Testnet Ether
  explorerUrl: "https://sepolia.etherscan.io",
  rpcUrl: "https://ethereum-sepolia-rpc.publicnode.com", // Replace with your Infura project ID
};
// 3. Create modal
const metadata = {
  name: "DODAS ERC404 Search Engine ",
  description:
    "DODAS is excited to present the world's first Multichain ERC404 Indexer with API support. Dive into the future of tokenization and explore the possibilities with ERC404!",
  url: "https://erc404.dodas.xyz/", // origin must match your domain & subdomain
  icons: ["https://avatars.mywebsite.com/"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet, sepolia],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

function App() {
  const navigate = useNavigate();
  const {
    searchQuery,
    receivedData,
    setReceivedData,
    setReceivedNFT,
    loading,
    isDarkMode,
    setIsDarkMode,
    setIsModalOpen,
    isModalOpen,
    notification,
    setNotification,
  } = useContext(AppContext);
  const {
    walletAddress,
    subscribe,
    setSubscribe,
    setKeyVerified,
    jwt,
    setJwt,
    setApikey,
    setIsAdmin,
  } = useContext(AppContext);

  const toggleMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
    // Toggle the "dark-mode" class on the body or another top-level element
    document.documentElement.classList.toggle("dark");
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const closeToastAndModal = () => {
    toast.dismiss();
    // toggleModal();
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (notification === "done") {
      toast.success("The NFTs will be added soon!");
      setNotification("");
    } else if (notification === "not done") {
      toast.error(`Failed to add NFTs :(`);
      setNotification("");
    }
  }, [notification]);

  useEffect(() => {
    async function fetchData() {
      try {
        let allData = [];
        if (searchQuery) {
          try {
            const NFTSearch = await axios.get(
              `${
                process.env.REACT_APP_BACKEND
              }/app/getNFT?nftName=${encodeURIComponent(searchQuery)}`
            );
            setReceivedNFT(NFTSearch.data);
          } catch (error) {
            console.log(error);
          }
          const { data } = await axios.get(
            `${
              process.env.REACT_APP_BACKEND
            }/app/collections?collectionName=${encodeURIComponent(searchQuery)}`
          );
          for (const item of data) {
            const NftResponse = await axios.get(
              `${
                process.env.REACT_APP_BACKEND
              }/app/nftByCollection?collectionName=${encodeURIComponent(
                item?.collection_slug
              )}`
            );

            item.nfts = NftResponse.data;

            allData.push(item);
          }
          setReceivedData(allData);
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }

    // Call the async function
    fetchData();
  }, [searchQuery]);

  useEffect(() => {
    async function subscribeStatus() {
      console.log(jwt);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/app/telegram/isSubscribed`,
          {
            headers: {
              Authorization: jwt,
            },
          }
        );
        if (response.data) {
          setSubscribe("Subscribed");
        }
      } catch (error) {
        console.error(error);
        setSubscribe("not Subscribed");
      }
    }

    async function keyVerificationStatus() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/app/telegram/isApiVerified`,
          {
            headers: {
              Authorization: jwt,
            },
          }
        );

        if (response.data) {
          setKeyVerified("Verified");
        }
      } catch (error) {
        console.error(error);
        setKeyVerified("not Verified");
      }
    }
    async function isAdmin() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/app/isAdmin`,
          {
            headers: {
              Authorization: jwt,
            },
          }
        );
        if (response.data === true) {
          setIsAdmin(true);
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (jwt !== "") {
      subscribeStatus();
      keyVerificationStatus();
      isAdmin();
    }
  }, [jwt]);

  useEffect(() => {
    // new login from user
    async function addUser() {
      try {
        if (walletAddress !== "") {
          const formData = new URLSearchParams();
          formData.append("walletAddress", walletAddress);

          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND}/app/addUser`,
            formData,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );
          // setJwt(response.data);
          setCookie("jwt", response.data);
        }
      } catch (error) {
        console.error(error);
      }
    }
    setJwt(getCookie("jwt") || "");
    if (jwt == "") {
      addUser();
    }
  }, [walletAddress]);

  useEffect(() => {
    // getting api key
    async function getApiKey() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/app/getApiKey`,
          {
            headers: {
              Authorization: jwt,
            },
          }
        );

        setApikey(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    try {
      if (jwt) {
        if (subscribe === "Subscribed") {
          // getting api key
          getApiKey();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [jwt, subscribe]);

  useEffect(() => {
    //creating api key
    async function addApiKey() {
      try {
        await axios.get(`${process.env.REACT_APP_BACKEND}/app/addApiKey`, {
          headers: {
            Authorization: jwt,
          },
        });
      } catch (error) {
        console.error(error);
      }
    }

    if (walletAddress && jwt) {
      try {
        // creating api key
        addApiKey();
      } catch (err) {
        console.log(err);
      }
    }
  }, [jwt, walletAddress]);

  return (
    <div className="App w-full min-h-screen flex flex-col">
      <Header />
      <label
        className={`${
          isDarkMode ? styles.dark : styles.light
        } fixed bottom-4 right-4 inline-flex items-center cursor-pointer z-50 bg-gray-900 ${
          styles.themeToggleButton
        }`}
      >
        <input
          type="checkbox"
          className="sr-only peer "
          checked={isDarkMode}
          onChange={toggleMode}
        />

        <div className={`w-8 h-8 ${styles.iconContainer} `}>
          <IoSunny className={`w-8 h-8 hidden dark:block`} />
          <IoMoon className={`w-8 h-8 dark:hidden text-white  `} />
        </div>
      </label>

      <Routes>
        <Route
          path="/"
          element={<HomePage receivedData={receivedData} loading={loading} />}
        />
        <Route
          path="/search"
          element={<SearchPage receivedData={receivedData} loading={loading} />}
        />
        <Route
          path="/collection/:collectionName"
          element={<ShowCollectionsPage />}
        />
        <Route path="/alpha" element={<AlphaPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="*" element={<Navigate to="/" />}></Route>
      </Routes>
      <ToastContainer onClose={closeToastAndModal} />
    </div>
  );
}

export default App;
