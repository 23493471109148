import React, { useContext, useEffect, useState } from "react";
import { useWeb3Modal } from "@web3modal/ethers5/react";
import { FaKey } from "react-icons/fa";
import { MdDashboard, MdLogout } from "react-icons/md";
import ApiKeyPage from "./ApiKeyPage";
import AdminPage from "./AdminPage";
import axios from "axios";
import { AppContext } from "../context/AppContext";

const ProfilePage = () => {
  const { open } = useWeb3Modal();
  const [tab, setTab] = useState("ApiKeyPage");
  const { isAdmin } = useContext(AppContext);

  // disconnecting/logging out
  const disconnectWallet = async () => {
    open();
  };

  return (
    <div className="flex w-full justify-center dark:bg-gray-900 bg-[#f6f6f6] flex-grow">
      <div className="flex w-full justify-start md:mx-32 ">
        {/* left sidebar */}
        <div className="w-1/8 pr-2 md:w-1/4">
          <div className=" py-4">
            <ul className=" space-y-2">
              <li>
                {isAdmin && (
                  <button
                    className={`w-full md:px-2 md:gap-2 flex flex-col items-center md:flex-row md: flex-wrap py-2 text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                      tab === "AdminPage" &&
                      "border-l-4 border-indigo-600 md:ml-2"
                    }`}
                    onClick={() => setTab("AdminPage")}
                  >
                    <MdDashboard className=" w-8 h-8 md:flex-shrink-0 md:w-6 md:h-6 text-gray-500 dark:text-gray-400" />
                    <span className="text-xs md:text-base font-semibold text-gray-700 dark:text-gray-300">
                      Admin
                    </span>
                  </button>
                )}
                <button
                  className={`w-full md:px-2 md:gap-2 flex flex-col items-center md:flex-row md: flex-wrap py-2 text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                    tab === "ApiKeyPage" &&
                    "border-l-4 border-indigo-600 md:ml-2"
                  }`}
                  onClick={() => setTab("ApiKeyPage")}
                >
                  <FaKey className="md:flex-shrink-0 w-6 h-6 md:w-4 md:h-4 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />

                  <span className="text-xs md:text-base md:flex-shrink-0 font-semibold text-gray-700 dark:text-gray-300 md:ml-[2px]">
                    API Keys
                  </span>
                </button>
                <button
                  onClick={disconnectWallet}
                  className="w-full  md:px-2 md:gap-2  flex flex-col items-center md:flex-row md:items-center py-2 text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <MdLogout className="w-8 h-8 md:flex-shrink-0 md:w-5 md:h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                  <span className="text-xs md:text-base font-semibold text-gray-700 dark:text-gray-300">
                    Logout
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>

        {/* main section */}
        {tab === "AdminPage" && <AdminPage />}
        {tab === "ApiKeyPage" && <ApiKeyPage />}
      </div>
    </div>
  );
};

export default ProfilePage;
