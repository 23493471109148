import React from "react";

const Progress = (props) => {
  const { buys, sells } = props;
  let total = buys + sells;

  return (
    <div className="text-gray-800 dark:text-white dark:bg-gray-700 rounded-md p-2">
      <div className="flex justify-between mb-1">
        <div className="text-green-600 flex flex-col">
          <div className=" font-bold">Buys</div>
          <div>{buys}</div>
        </div>
        <div className="flex flex-col text-center">
          <div className="font-bold">Txns</div>
          <div className="">{buys + sells}</div>
        </div>
        <div className="text-red-600 flex flex-col">
          <div className="font-bold">Sells</div>
          <div className="text-right">{sells}</div>
        </div>
      </div>

      <div className="flex ">
        <div
          className="bg-green-600 h-2.5 rounded-full dark:bg-green-500"
          style={{ width: `${(buys / total) * 100}%` }}
        />
        <div
          className="bg-red-600 h-2.5 rounded-full dark:bg-red-500"
          style={{ width: `${(sells / total) * 100}%` }}
        />
      </div>
    </div>
  );
};

export default Progress;
