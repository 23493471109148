import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";

const NftCard = (props) => {
  const { nftData } = props;

  const isVideo = /\.(mp4|ogg|webm)$/.test(nftData?._source?.image_url);

  return (
    <div
      style={{ borderRadius: "20px" }}
      className="min-w-[12rem] md:w-60 bg-gray-50 dark:bg-gray-800 text-gray-200 shadow-lg rounded-lg overflow-hidden lg:mr-4"
    >
      {isVideo ? (
        <video loop autoPlay>
          <source
            src={nftData?._source?.image_url}
            type={`video/${nftData?._source?.image_url.split(".").pop()}`}
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <img
          style={{ borderRadius: "20px" }}
          className="w-full h-40 sm:h-44 md:h-48 object-cover object-center"
          src={
            nftData?._source?.image_url ||
            "https://res.cloudinary.com/djuseai07/image/upload/v1706781066/njvqqes5lysniv5qqrbq.jpg"
          }
          alt="Nft Image"
          loading="lazy"
          onError={(e) => {
            e.target.src =
              "https://res.cloudinary.com/djuseai07/image/upload/v1706781066/njvqqes5lysniv5qqrbq.jpg";
          }}
        />
      )}

      <div className="p-4">
        <h2 className="text-lg font-semibold text-gray-800 dark:text-white mb-2 line-clamp-1">
          {nftData?._source?.name || `#${nftData?._source?.identifier}`}
        </h2>

        <p className="text-gray-600 dark:text-gray-400 text-medium mb-2 line-clamp-1 ">
          <a
            href={nftData?._source?.opensea_url}
            target="_blank"
            className="flex gap-2 items-center hover:text-indigo-600"
          >
            <span>View Details </span>
            <FaArrowRight />
          </a>
        </p>
      </div>
    </div>
  );
};

export default NftCard;
