// Function to set a cookie
export const setCookie = (name, value) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 1); // Set expiration to 1 day from now

  document.cookie = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
};

// Function to get the value of a cookie
export const getCookie = (name) => {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.trim().split("=");
    if (cookieName === name) {
      return cookieValue;
    }
  }
  return null;
};

export const calculateTimeSince = (created) => {
  const currentTime = new Date();
  const createdAt = new Date(created);
  const timeDifference = currentTime - createdAt;

  // Calculate years, months, days, hours, and minutes
  const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));
  const months = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24 * 365)) /
      (1000 * 60 * 60 * 24 * 30.44)
  ); // Average days in a month
  const days = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24 * 30.44)) / (1000 * 60 * 60 * 24)
  );
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  let timeString = "";

  if (years > 0) {
    timeString += `${years}y ago`;
    return timeString.trim();
  }
  if (months > 0) {
    timeString += `${months}mo ago`;
    return timeString.trim();
  }
  if (days > 0) {
    timeString += `${days}d ago`;
    return timeString.trim();
  }
  if (hours > 0) {
    timeString += `${hours}h ago`;
    return timeString.trim();
  }
  if (minutes > 0) {
    timeString += `${minutes}min ago`;
    return timeString.trim();
  }

  return timeString.trim();
};

export function convertToMillionFormat(amount) {
  // Convert the amount to billions, millions, thousands
  if (isNaN(amount)) {
    return "--";
  }
  if (amount >= 1e9) {
    return (amount / 1e9).toFixed(1) + "B";
  } else if (amount >= 1e6) {
    return (amount / 1e6).toFixed(1) + "M";
  } else if (amount >= 1e3) {
    return (amount / 1e3).toFixed(1) + "K";
  } else {
    return amount;
  }
}

export const compareByFDV = (a, b) => {
  // Handle cases where either object doesn't have 'fdv' attribute
  if (!a.hasOwnProperty("fdv") || !b.hasOwnProperty("fdv")) {
    // If 'a' doesn't have 'fdv', it should come after 'b'
    if (!a.hasOwnProperty("fdv")) return 1;
    // If 'b' doesn't have 'fdv', it should come before 'a'
    if (!b.hasOwnProperty("fdv")) return -1;
  }
  // Compare 'fdv' attributes in descending order
  return b.fdv - a.fdv;
};

// redirecting to a new page with that url
export const handleRedirect = (newUrl) => {
  window.open(newUrl, "_blank");
};

// Function to format contract address with ellipsis (shorter format)
export const formatContractAddress = (address) => {
  const firstPart = address.substring(0, 12);
  const lastPart = address.substring(address.length - 3);
  return `${firstPart}...${lastPart}`;
};
