import React, { createContext, useState } from "react";
import { userDummyData } from "../components/Dummy";

const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [collections, setCollections] = useState([]);
  const [collectionsData, setCollectionsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [receivedData, setReceivedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(0);
  const [receivedNFT, setReceivedNFT] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [allChains, setAllChains] = useState([]);
  const [subscribe, setSubscribe] = useState("");
  const [apikey, setApikey] = useState("");
  const [jwt, setJwt] = useState("");
  const [keyVerified, setKeyVerified] = useState("");
  const [notification, setNotification] = useState("");
  const [userData, setUserData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  const logoSource = {
    ethereum: "https://cryptologos.cc/logos/ethereum-eth-logo.png?v=029",
    arbitrum: "https://cryptologos.cc/logos/arbitrum-arb-logo.png?v=029",
    base: "https://dd.dexscreener.com/ds-data/chains/base.png",
    avalanche: "https://cryptologos.cc/logos/avalanche-avax-logo.png?v=029",
    bsc: "https://cryptologos.cc/logos/bnb-bnb-logo.png?v=029",
  };

  return (
    <AppContext.Provider
      value={{
        collections,
        setCollections,
        collectionsData,
        setCollectionsData,
        searchQuery,
        setSearchQuery,
        receivedData,
        setReceivedData,
        receivedNFT,
        setReceivedNFT,
        loading,
        setLoading,
        dataLoaded,
        setDataLoaded,
        isModalOpen,
        setIsModalOpen,
        walletAddress,
        setWalletAddress,
        isDarkMode,
        setIsDarkMode,
        allChains,
        setAllChains,
        logoSource,
        subscribe,
        setSubscribe,
        apikey,
        setApikey,
        jwt,
        setJwt,
        keyVerified,
        setKeyVerified,
        notification,
        setNotification,
        userData,
        setUserData,
        isAdmin,
        setIsAdmin,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
