import React, { useState } from "react";
import { FaEye, FaEyeSlash, FaCopy } from "react-icons/fa";
import CopyToClipboard from "react-copy-to-clipboard";

const ManageApiKeys = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [copied, setCopied] = useState("");
  const { apikey } = props;

  const handleCopy = (type) => {
    setCopied(type);
    // You can reset the copied state after a certain duration if needed
    setTimeout(() => setCopied(""), 2000);
  };

  return (
    <div className="dark:bg-gray-800 bg-white p-8 pb-4 mt-1 rounded-[30px]">
      <div className="mb-2">
        <h2 className="text-lg font-semibold text-gray-700 dark:text-gray-300 mb-2">
          API Key
        </h2>

        {apikey && (
          <div className="mt-4 flex items-center">
            <input
              type={showPassword ? "text" : "password"}
              value={apikey}
              readOnly
              className="w-full py-2 px-4 border rounded-md"
            />
            <button
              onClick={() => setShowPassword(!showPassword)}
              className="ml-2 focus:outline-none"
            >
              {showPassword ? (
                <FaEyeSlash className="text-indigo-600 hover:text-blue-700 cursor-pointer text-lg" />
              ) : (
                <FaEye className="text-indigo-600 hover:text-blue-700 cursor-pointer text-lg" />
              )}
            </button>
            <CopyToClipboard text={apikey} onCopy={() => handleCopy(apikey)}>
              <button
                className={`ml-2 px-2 py-2 text-base font-medium text-center ${
                  copied === apikey
                    ? "text-white bg-indigo-600"
                    : "text-indigo-600 hover:text-white hover:bg-indigo-600"
                } rounded-lg focus:outline-none transition-all cursor-pointer text-lg`}
              >
                {copied === apikey ? "Copied" : <FaCopy />}
              </button>
            </CopyToClipboard>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageApiKeys;
