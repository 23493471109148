import React, { useContext, useMemo, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { MdContentCopy } from "react-icons/md";
import { formatContractAddress } from "../utils/utils";
import { userDummyData } from "./Dummy";
import { IoCheckmarkSharp, IoCloseSharp } from "react-icons/io5";
import { AppContext } from "../context/AppContext";

const UserTable = () => {
  const [copied, setCopied] = useState("");
  const { userData } = useContext(AppContext);
  const handleCopy = (type) => {
    // e.stopPropagation();
    setCopied(type);
    // You can reset the copied state after a certain duration if needed
    setTimeout(() => setCopied(""), 2000);
    // Handle any other copy logic if needed
  };
  return (
    <div className="relative overflow-y-auto max-h-[25rem]">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="sticky top-0 z-50 text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-900 dark:text-white">
          <tr>
            <th scope="col" className="px-4 py-3">
              Wallet Address
            </th>
            <th scope="col" className="px-4 py-3 ">
              Subscribed
            </th>
            <th scope="col" className="px-4 py-3">
              Telegram
            </th>
          </tr>
        </thead>
        <tbody>
          {userData?.all?.map((item) => (
            <tr className="bg-white border-b dark:bg-gray-700 dark:border-gray-800">
              <th
                scope="row"
                className="px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                <span>{formatContractAddress(item?.walletAddress || "")}</span>
                <CopyToClipboard
                  text={item?.walletAddress}
                  onCopy={() => handleCopy(item.walletAddress)}
                >
                  <button
                    onClick={(e) => e.stopPropagation()}
                    className={`z-50 p-2 text-xs font-medium text-center ${
                      copied === item.address
                        ? "text-white bg-blue-700"
                        : "text-blue-700 hover:text-white hover:bg-blue-700"
                    } rounded-lg focus:outline-none transition-all cursor-pointer`}
                  >
                    {copied === item.walletAddress ? (
                      "Copied"
                    ) : (
                      <MdContentCopy />
                    )}
                  </button>
                </CopyToClipboard>
              </th>
              <td
                className={`px-4 py-4 text-right ${
                  item.subscribed ? "text-[#16a34a]" : "text-[#dc2626]"
                } `}
              >
                {item.subscribed ? (
                  <IoCheckmarkSharp size={20} />
                ) : (
                  <IoCloseSharp size={20} />
                )}
              </td>
              <td
                className={`px-4 py-4 ${
                  item.telegram ? "text-[#16a34a]" : "text-[#dc2626]"
                } `}
              >
                {item.telegram ? (
                  <IoCheckmarkSharp size={20} />
                ) : (
                  <IoCloseSharp size={20} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
