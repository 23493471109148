import {
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { useContext, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers";

export default function LoginPage() {
  // 4. Use modal hook
  const { open } = useWeb3Modal();
  const { address, isConnected } = useWeb3ModalAccount();
  const navigate = useNavigate();
  const { walletAddress, setWalletAddress } = useContext(AppContext);
  const { disconnect } = useDisconnect();

  const { walletProvider } = useWeb3ModalProvider();

  async function onSignMessage() {
    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      await signer?.signMessage(
        "By proceeding, you grant consent for us to access and utilize your wallet information. Rest assured, you retain full control and can revoke access at any time. Your continued use of our platform signifies your acknowledgment and agreement to the terms outlined in this notice."
      );
    } catch (e) {
      console.log(e);
      disconnect();
      setWalletAddress("");
      navigate("/");
    }
  }

  useEffect(() => {
    if (isConnected) {
      try {
        onSignMessage();
        setWalletAddress(address);
      } catch (error) {}
    } else {
      setWalletAddress("");
      navigate("/");
    }
  }, [isConnected]);

  return (
    <li>
      {walletAddress ? (
        <span
          className="font-extrabold text-sm block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            navigate("/profile");
          }}
        >
          Profile
        </span>
      ) : (
        <span
          className="text-white bg-indigo-600 hover:bg-indigo-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 mb-2 dark:-indigo-600 dark:hover:bg-indigo-700 focus:outline-none dark:focus:ring-blue-800 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            open();
          }}
        >
          Connect
        </span>
      )}
    </li>
  );
}
